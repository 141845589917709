import React, { lazy, Suspense } from 'react';
import { useLocation, Route } from 'react-router-dom';

// snipet
// import Header from '../components/snippet/Header';
// import HeaderLogin from '../components/snippet/HeaderLogin';
// import Footer from '../components/snippet/Footer';
const DynamicHeader = lazy(() => import('../components/snippet/Header'));
const DynamicHeaderLogin = lazy(() => import('../components/snippet/HeaderLogin'));
const DynamicFooter = lazy(() => import('../components/snippet/Footer'));

// import Homepage from '../components/Homepage';
// import HomepageSupplier from '../components/HomepageSuppliers';
// import HomepageAdvertiseLanding from '../components/HomepageAdvertiseLanding';
// import Sitemap from "../components/Sitemap";
// import Login from "../components/Login";
// import About from '../components/about';
// import ContactUs from '../components/ContactUs';
// import FAQ from '../components/Faq';
// import TermsConditions from '../components/TermsConditions';
// import Policy from '../components/Policy';
// import OurBlog from '../components/OurBlog';
// import SingleBlog from '../components/SingleBlog';
// import Search from '../components/Search';
// import GetStarted from '../components/GetStarted';
// import ForgotPassword from '../components/ForgotPassword';
// import Webinar from '../components/webinar';

const DynamicHomepage = lazy(() => import('../components/Homepage'));
const DynamicHomepageSupplier = lazy(() => import('../components/HomepageSuppliers'));
const DynamicHomepageAdvertiseLanding = lazy(() => import('../components/HomepageAdvertiseLanding'));
const DynamicSitemap = lazy(() => import("../components/Sitemap"));
const DynamicLogin = lazy(() => import("../components/Login"));
const DynamicAbout = lazy(() => import('../components/about'));
const DynamicContactUs = lazy(() => import('../components/ContactUs'));
const DynamicFAQ = lazy(() => import('../components/Faq'));
const DynamicTermsConditions = lazy(() => import('../components/TermsConditions'));
const DynamicPolicy = lazy(() => import('../components/Policy'));
const DynamicOurBlog = lazy(() => import('../components/OurBlog'));
const DynamicSingleBlog = lazy(() => import('../components/SingleBlog'));
const DynamicSearch = lazy(() => import('../components/Search'));
const DynamicGetStarted = lazy(() => import('../components/GetStarted'));
const DynamicForgotPassword = lazy(() => import('../components/ForgotPassword'));
const DynamicWebinar = lazy(() => import('../components/webinar'));

// import OrganizerSignUp from "../components/organizer/SignUp";
// import OrganizerFewMoreDetails from "../components/organizer/FewMoreDetails";

const DynamicOrganizerSignUp = lazy(() => import("../components/organizer/SignUp"));
const DynamicOrganizerFewMoreDetails = lazy(() => import("../components/organizer/FewMoreDetails"));

// import FreelancerSignUp from "../components/freelancer/SignUp";
// import FreelancerFewMoreDetails from "../components/freelancer/FewMoreDetails";

const DynamicFreelancerSignUp = lazy(() => import("../components/freelancer/SignUp"));
const DynamicFreelancerFewMoreDetails = lazy(() => import("../components/freelancer/FewMoreDetails"));

// import SupplierGetstarted from "../components/GetStartedSupplier";
// import SupplierSignup from "../components/supplier/SignUp";
// import SupplierFewMoreDetails from "../components/supplier/FewMoreDetails";

const DynamicSupplierGetstarted = lazy(() => import("../components/GetStartedSupplier"));
const DynamicSupplierSignup = lazy(() => import("../components/supplier/SignUp"));
const DynamicSupplierFewMoreDetails = lazy(() => import("../components/supplier/FewMoreDetails"));


// import NotFound from '../components/notFound'
// import Resourcespage from '../components/OurResources';
// import HomePageNew from '../components/HomePageNew';

const DynamicNotFound = lazy(() => import('../components/notFound'));
const DynamicResourcespage = lazy(() => import('../components/OurResources'));
const DynamicHomePageNew = lazy(() => import('../components/HomePageNew'));
// import FreelancerDashboard from "../components/freelancer/dashboard";
// import OrganizerDashboard from "../components/organizer/dashboard";
// import SupplierDashboard from "../components/supplier/dashboard";


const Primarylayout = ({view}) => {
    const { pathname } = useLocation();
    // console.log(pathname);
    const userAccess = pathname === '/forgot-password' || pathname === '/forgot-password/' || pathname === '/login' || pathname === '/login/' || pathname === '/get-started' || pathname === '/s/get-started' || pathname === '/e/sign-up-today-and-post-your-first-job' || pathname === '/f/sign-up-today-and-find-your-first-job' || pathname === '/s/sign-up-today-supplier' || pathname === '/e/few-more-details' || pathname === '/f/few-more-details' || pathname === '/s/few-more-details' ;

    let AuthFree = localStorage.getItem('auth_empplyFreeUID');
    let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
    let Authsup = localStorage.getItem('auth_empplySplierUID');
    let Auths = AuthFree || AuthOrg || Authsup;

    return (
        <div className={`page-data ${view}`}>
            <Suspense fallback={<div>Loading...</div>}>
                {userAccess ? <DynamicHeaderLogin /> : <DynamicHeader />}

                {pathname === "/old" && <DynamicHomepage />}
                {pathname === "/" && <DynamicHomePageNew />}
                {pathname === "/sitemap" && <DynamicSitemap />}
                {pathname === "/about" && <DynamicAbout />}
                {pathname === "/about-us" && <DynamicAbout />}
                {pathname === "/contact-us" && <DynamicContactUs />}
                {pathname === "/faq" && <DynamicFAQ />}
                {pathname === "/terms-conditions" && <DynamicTermsConditions />}
                {pathname === "/privacy-policy" && <DynamicPolicy />}
                {pathname === "/blog" && <DynamicOurBlog />}
                {pathname === "/resources" && <DynamicResourcespage />}
                <Route path="/post/:id">
                    <DynamicSingleBlog />
                </Route>
                <Route path="/search" render={(props) => <DynamicSearch {...props}/>}></Route>
                {/* {pathname === "/search" && <Search />} */}
                {pathname === "/supplier" && <DynamicHomepageSupplier />}
                {pathname === "/advertise" && <DynamicHomepageAdvertiseLanding />}


                {pathname === "/login" && <DynamicLogin />}
                {pathname === "/login/" && <DynamicLogin />}
                {pathname === "/get-started" && <DynamicGetStarted />}
                {pathname === "/forgot-password" && <DynamicForgotPassword />}  
                {pathname === "/forgot-password/" && <DynamicForgotPassword />}  
                {pathname === "/s/get-started" && <DynamicSupplierGetstarted />}  
                
                {pathname === "/e/sign-up-today-and-post-your-first-job" && <DynamicOrganizerSignUp />}
                {pathname === "/f/sign-up-today-and-find-your-first-job" && <DynamicFreelancerSignUp />}
                {pathname === "/s/sign-up-today-supplier" && <DynamicSupplierSignup />}  

                {pathname === "/e/few-more-details" && <DynamicOrganizerFewMoreDetails />}  
                {pathname === "/f/few-more-details" && <DynamicFreelancerFewMoreDetails />}  
                {pathname === "/s/few-more-details" && <DynamicSupplierFewMoreDetails />}  
                
                {pathname === "/webinars" && <DynamicWebinar />}
                {/* <Route path='*' component={NotFound} />  */}
                {/* {pathname === "*" && <NotFound />}  */}
                <DynamicNotFound view="landing" />
                {!Auths && <DynamicFooter />}
            </Suspense>
        </div>
    );
}

export default React.memo(Primarylayout);