import React, { lazy, Suspense } from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';


// import DashboardHeader from '../components/supplier/DashboardHeader';
// import SupplierMembership from "../components/supplier/MembershipPlan";
// import SupplierDashboard from "../components/supplier/dashboard";
// import SupplierPayment from "../components/supplier/Payment";
// import SupplierNotification from "../components/supplier/Notification";
// import SupplierProfile from "../components/supplier/Profile";
// import SupplierProfileChange from "../components/supplier/ProfileChange";
// import SupplierContactAdmin from "../components/supplier/ContactAdmin";
// import NotFound from '../components/notFound';
// import ThankYouPayment from "../components/supplier/ThankYouPayment";

const DynamicDashboardHeader = lazy(() => import('../components/supplier/DashboardHeader'));
const DynamicSupplierMembership = lazy(() => import("../components/supplier/MembershipPlan"));
const DynamicSupplierDashboard = lazy(() => import("../components/supplier/dashboard"));
const DynamicSupplierPayment = lazy(() => import("../components/supplier/Payment"));
const DynamicSupplierNotification = lazy(() => import("../components/supplier/Notification"));
const DynamicSupplierProfile = lazy(() => import("../components/supplier/Profile"));
const DynamicSupplierProfileChange = lazy(() => import("../components/supplier/ProfileChange"));
const DynamicSupplierContactAdmin = lazy(() => import("../components/supplier/ContactAdmin"));
const DynamicNotFound = lazy(() => import('../components/notFound'));
const DynamicThankYouPayment = lazy(() => import("../components/supplier/ThankYouPayment"));

// import NotFound from '../components/notFound'
const PrimarylayoutSupplier = () => {
    const {pathname} = useLocation();

    let AuthSup = localStorage.getItem('auth_empplySplierUID');

    if(!AuthSup){
        return <Redirect push to="/" />
    }
    return (
        <div id="wrapper">
            <Suspense fallback={<div>Loading...</div>}>
            {AuthSup && <DynamicDashboardHeader />}
                            
            {pathname === "/s/membership" && <DynamicSupplierMembership />}
            {pathname === "/s/dashboard" && <DynamicSupplierDashboard />}
            {/* {pathname === "/s/payment" && <SupplierPayment />} */}
            <Route path="/s/payment" component={DynamicSupplierPayment} />
            {pathname === "/s/notifications" && <DynamicSupplierNotification />}
            {pathname === "/s/profile" && <DynamicSupplierProfile />}
            {pathname === "/s/change-password" && <DynamicSupplierProfileChange />}
            {pathname === "/s/contact-admin" && <DynamicSupplierContactAdmin />}
            {pathname === "/s/thank-you-payment" && <DynamicThankYouPayment />}
            {pathname === "/" && <DynamicSupplierDashboard />}
            
            <DynamicNotFound view="dashboard" />
            {/* <Route path="*" component={NotFound} /> */}
            </Suspense>
        </div>
    );
}

export default React.memo(PrimarylayoutSupplier);