import React, {useState, useEffect} from 'react';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route
// } from "react-router-dom";
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import ScrollTop from "./components/snippet/ScrollTop";

// import Homepage from './components/Homepage';
// import HomepageSupplier from './components/HomepageSuppliers';
// import HomepageAdvertiseLanding from './components/HomepageAdvertiseLanding';
// import Sitemap from "./components/Sitemap";
// import Login from "./components/Login";
// import About from './components/about';
// import ContactUs from './components/ContactUs';
// import FAQ from './components/Faq';
// import TermsConditions from './components/TermsConditions';
// import Policy from './components/Policy';
// import OurBlog from './components/OurBlog';
// import SingleBlog from './components/SingleBlog';
// import Search from './components/Search';
// import GetStarted from './components/GetStarted';
// import ForgotPassword from './components/ForgotPassword';

// organizer
// import OrganizerSignUp from "./components/organizer/SignUp";
// import OrganizerFewMoreDetails from "./components/organizer/FewMoreDetails";
// import OrganizerDashboard from "./components/organizer/dashboard";
// import OrganizerProfile from "./components/organizer/Profile";
// import OrganizerProfileChange from "./components/organizer/ProfileChange";
// import OrganizerJobPost from "./components/organizer/PostAJob";
// import OrganizerJobPostUpdate from "./components/organizer/PostAJobUpdate";
// import OrganizerCurrentJobs from "./components/organizer/CurrentJobs";
// import OrganizerMemberShip from "./components/organizer/MembershipPlan";
// import OrganizerPayment from "./components/organizer/Payment";
// import OrganizerRating from "./components/organizer/MyRating";
// import OrganizerPreviousJobs from "./components/organizer/PreviousJobs";
// import OrganizerMessage from "./components/organizer/Chat";
// import OrganizerMessageInner from "./components/organizer/ChatInner";
// import OrganizerCalendar from "./components/organizer/Calendar";
// import OrganizerThankYou from "./components/organizer/ThankYou";
// import OrganizerSingleJob from "./components/organizer/SingleJob";
// import OrganizerLeaveRating from "./components/organizer/LeaveRating";
// import OrganizerFreelancerSingle from "./components/organizer/FreelancerSingle";
// import OrganizerNotifications from "./components/organizer/Notification";
// import OrganizerSuppliers from "./components/organizer/Suppliers";
// import OrganizerSuppliersSingle from "./components/organizer/SuppliersSingle";
// import OrganizerSuppliersSingleOpen from "./components/organizer/SuppliersSingleOpen";
// import OrganizerFavourites from "./components/organizer/MyFavourites";

// Freelancer
// import FreelancerSignUp from "./components/freelancer/SignUp";
// import FreelancerFewMoreDetails from "./components/freelancer/FewMoreDetails";
// import FreelancerDashboard from "./components/freelancer/dashboard";
// import FreelancerProfile from "./components/freelancer/Profile";
// import FreelancerProfileChange from "./components/freelancer/ProfileChange";
// import FreelancerProfileComplete from "./components/freelancer/ProfileComplete";
// import FreelancerMembership from "./components/freelancer/MembershipPlan";
// import FreelancerPayment from "./components/freelancer/Payment";
// import FreelancerAccepted from "./components/freelancer/AcceptedJobs";
// import FreelancerRequested from "./components/freelancer/RequestJobs";
// import FreelancerCompleted from "./components/freelancer/CompletedJobs";
// import FreelancerSingleJob from "./components/freelancer/SingleJob";
// import FreelancerChat from "./components/freelancer/Chat";
// import FreelancerChatInner from "./components/freelancer/ChatInner";
// import FreelancerCalendar from "./components/freelancer/Calendar";
// import FreelancerCalendarAvailability from "./components/freelancer/CalendarAvailability";
// import FreelancerLeaveRating from "./components/freelancer/LeaveRating";
// import FreelancerMyRating from "./components/freelancer/MyRating";
// import FreelancerNotifications from "./components/freelancer/Notification";
// import FreelancerSuppliers from "./components/freelancer/Suppliers";
// import FreelancerSuppliersSingle from "./components/freelancer/SuppliersSingle";
// import FreelancerSuppliersSingleOpen from "./components/freelancer/SuppliersSingleOpen";
// import FreelancerFavourites from "./components/freelancer/MyFavourites";

// Supplier
// import SupplierGetstarted from "./components/GetStartedSupplier";
// import SupplierSignup from "./components/supplier/SignUp";
// import SupplierFewMoreDetails from "./components/supplier/FewMoreDetails";
// import SupplierMembership from "./components/supplier/MembershipPlan";
// import SupplierDashboard from "./components/supplier/dashboard";
// import SupplierPayment from "./components/supplier/Payment";
// import SupplierNotification from "./components/supplier/Notification";
// import SupplierProfile from "./components/supplier/Profile";
// import SupplierProfileChange from "./components/supplier/ProfileChange";
// import SupplierContactAdmin from "./components/supplier/ContactAdmin";


// base layout
import BaseLayout from './global/BaseLayout'
import OrgLayout from './global/PrimaryLayoutOrg'
import FreeLayout from './global/PrimaryLayoutFreelancer'
import SupplierLayout from './global/PrimaryLayoutSupplier'

export default function App() {

  const [redirectFree, setRedirectFree] = useState(false);
  const [redirectOrg, setRedirectOrg] = useState(false);
  const [redirectSup, setRedirectSup] = useState(false);
  const [landing, setLanding] = useState('');

  let pagename = landing;
  useEffect(() => {
    
    setInterval(() => {
      let AuthFree = localStorage.getItem('auth_empplyFreeUID');
      let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
      let Authsup = localStorage.getItem('auth_empplySplierUID');
    
      if(AuthOrg){
        setRedirectOrg(true);
      }else{
        setRedirectOrg(false);
        setLanding('landing');
      }

      if(AuthFree){
        setRedirectFree(true);
      }else{
        setRedirectFree(false);
        setLanding('landing');
      }
      
      if(Authsup){
        setRedirectSup(true);
      }else{
        setRedirectSup(false);
        setLanding('landing');
      }

    }, 100);
  }, [])

  if(redirectOrg || redirectFree || redirectSup){
    if(redirectOrg){
      pagename = 'org';
    }
    if(redirectFree){
      pagename = 'free';
    }
    if(redirectSup){
      pagename = 'supplier';
    }
  }else{
    pagename = landing;
  }

  

  // console.log(pagename);

  // const location = window.location;
  // const loc2 = location.pathname.substring(0, 2);
  // console.log(loc2);
  return (
    <div id="app" className={`view-${pagename}`}>
      <BrowserRouter>
          <ScrollTop />
          <Switch>
            <Route path="/">          
              {(() => {
                if(pagename === "free"){
                  return <FreeLayout />;
                }else if(pagename === "org"){
                  return <OrgLayout />;
                }else if(pagename === "supplier"){
                  return <SupplierLayout />;
                }else if(pagename === 'landing'){
                  return <BaseLayout />;
                }
              })()}
            </Route>
          </Switch>
          
          {/* <Switch>   */}
            {/* <Route path="/sitemap" component={Sitemap}/>
            <Route path="/about" component={About}/>
            <Route path="/contact-us" component={ContactUs}/>
            <Route path="/faq" component={FAQ}/>
            <Route path="/terms-conditions" component={TermsConditions}/>
            <Route path="/privacy-policy" component={Policy}/>
            <Route path="/blog" component={OurBlog}/>
            <Route path="/post/:id" component={SingleBlog}/>
            <Route path="/search" component={Search}/>
            
            <Route path="/login" component={Login}/>
            <Route path="/get-started" component={GetStarted}/>
            <Route path="/forgot-password" component={ForgotPassword}/> */}
            
            
            {/* Organizer */}
            {/* <Route path="/e/sign-up-today-and-post-your-first-job" component={OrganizerSignUp}/>
            <Route path="/e/few-more-details" component={OrganizerFewMoreDetails}/>
            <Route path="/e/profile" component={OrganizerProfile}/>
            <Route path="/e/change-password" component={OrganizerProfileChange}/>
            <Route path="/e/job-post" component={OrganizerJobPost}/>
            <Route path="/e/job-post-update/:id" component={OrganizerJobPostUpdate}/>
            <Route path="/e/current-jobs" component={OrganizerCurrentJobs}/>
            <Route path="/e/membership" component={OrganizerMemberShip}/>
            <Route path="/e/payment" component={OrganizerPayment}/>
            <Route path="/e/my-rating" component={OrganizerRating}/>
            <Route path="/e/previous-jobs" component={OrganizerPreviousJobs}/>
            <Route path="/e/chat" component={OrganizerMessage}/>
            <Route path="/e/user/:id" component={OrganizerMessageInner}/>
            <Route path="/e/calendar" component={OrganizerCalendar}/>
            <Route path="/e/thank-you" component={OrganizerThankYou}/>
            <Route path="/e/job/:id" component={OrganizerSingleJob}/>
            <Route path="/e/leave-rating" component={OrganizerLeaveRating}/>
            <Route path="/e/freelancer/:id" component={OrganizerFreelancerSingle}/>
            <Route path="/e/notifications" component={OrganizerNotifications}/>
            <Route path="/e/suppliers" component={OrganizerSuppliers}/>
            <Route path="/e/my-favourites" component={OrganizerFavourites}/>
            <Route path="/e/supplier/:id" component={pagename === "org" ? OrganizerSuppliersSingle : OrganizerSuppliersSingleOpen}/> */}
            

            {/* Freelancer */}
            {/* <Route path="/f/sign-up-today-and-find-your-first-job" component={FreelancerSignUp}/>
            <Route path="/f/few-more-details" component={FreelancerFewMoreDetails}/>
            <Route path="/f/profile" component={FreelancerProfile}/>
            <Route path="/f/change-password" component={FreelancerProfileChange}/>
            <Route path="/f/complete-profile" component={FreelancerProfileComplete}/>
            <Route path="/f/membership" component={FreelancerMembership}/>
            <Route path="/f/payment" component={FreelancerPayment}/>
            <Route path="/f/accepted" component={FreelancerAccepted}/>
            <Route path="/f/offers" component={FreelancerRequested}/>
            <Route path="/f/completed" component={FreelancerCompleted}/>
            <Route path="/f/job/:id" component={FreelancerSingleJob}/>
            <Route path="/f/chat" component={FreelancerChat}/>
            <Route path="/f/user/:id" component={FreelancerChatInner}/>
            <Route path="/f/calendar" component={FreelancerCalendar}/>
            <Route path="/f/availability" component={FreelancerCalendarAvailability}/>
            <Route path="/f/leave-rating" component={FreelancerLeaveRating}/>
            <Route path="/f/my-rating" component={FreelancerMyRating}/>
            <Route path="/f/my-favourites" component={FreelancerFavourites}/>
            <Route path="/f/notifications" component={FreelancerNotifications}/>
            <Route path="/f/suppliers" component={FreelancerSuppliers}/>
            <Route path="/f/supplier/:id" component={pagename === "free" ? FreelancerSuppliersSingle : FreelancerSuppliersSingleOpen}/> */}
            
            {/* Supplier */}
            {/* <Route path="/s/get-started" component={SupplierGetstarted}/>
            <Route path="/s/sign-up-today-supplier" component={SupplierSignup}/>
            <Route path="/s/few-more-details" component={SupplierFewMoreDetails}/>
            <Route path="/s/membership" component={SupplierMembership}/>
            <Route path="/s/dashboard" component={SupplierDashboard}/>
            <Route path="/s/payment" component={SupplierPayment}/>
            <Route path="/s/notifications" component={SupplierNotification}/>
            <Route path="/s/profile" component={SupplierProfile}/>
            <Route path="/s/change-password" component={SupplierProfileChange}/>
            <Route path="/s/contact-admin" component={SupplierContactAdmin}/>
            
            <Route path="/supplier"><HomepageSupplier /></Route>
            <Route path="/advertise"><HomepageAdvertiseLanding /></Route> */}

            {/* <Route path={(() => {
              if(pagename === "free"){
                return "/f/dashboard"
              }else if(pagename === "org"){
                return "/e/dashboard"
              }else if(pagename === "supplier"){
                return "/s/dashboard"
              }else{
                return "/"
              }
            })()}>          
              {(() => {
                if(pagename === "free"){
                  return <FreelancerDashboard />;
                }else if(pagename === "org"){
                  return <OrganizerDashboard />;
                }else if(pagename === "supplier"){
                  return <SupplierDashboard />;
                }else{
                  return <Homepage />;
                }
              })()}
            </Route> */}

            {/* <Route path="/"><Homepage /></Route> */}

          {/* </Switch> */}

          {/* {pagename == "home" ? <Footer /> : (loc2 !== '/e' && loc2 !== '/s' && loc2 !== '/f' &&  <Footer />)} */}
      </BrowserRouter>
    </div>
  );
}
