import React, { lazy, useState, Suspense } from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

// snipet
// import DashboardFreeHeader from '../components/freelancer/DashboardHeader';
// import Search from '../components/snippet/SearchDashboardFree';
const DynamicDashboardFreeHeader = lazy(() => import('../components/freelancer/DashboardHeader'));
const DynamicSearch = lazy(() => import('../components/snippet/SearchDashboardFree'));


// import FreelancerDashboard from "../components/freelancer/dashboard";
// import FreelancerProfile from "../components/freelancer/Profile";
// import FreelancerProfileChange from "../components/freelancer/ProfileChange";
// import FreelancerProfileComplete from "../components/freelancer/ProfileComplete";
// import FreelancerMembership from "../components/freelancer/MembershipPlan";
// import FreelancerPayment from "../components/freelancer/Payment";
// import FreelancerAccepted from "../components/freelancer/AcceptedJobs";
// import FreelancerRequested from "../components/freelancer/RequestJobs";
// import FreelancerCompleted from "../components/freelancer/CompletedJobs";
// import FreelancerSingleJob from "../components/freelancer/SingleJob";
// import FreelancerChat from "../components/freelancer/Chat";
// import FreelancerChatInner from "../components/freelancer/ChatInner";
// import FreelancerCalendar from "../components/freelancer/Calendar";
// import FreelancerCalendarAvailability from "../components/freelancer/CalendarAvailability";
// import FreelancerLeaveRating from "../components/freelancer/LeaveRating";
// import FreelancerMyRating from "../components/freelancer/MyRating";
// import FreelancerNotifications from "../components/freelancer/Notification";
// import FreelancerSuppliers from "../components/freelancer/Suppliers";
// import FreelancerSuppliersSingle from "../components/freelancer/SuppliersSingle";
// import FreelancerSuppliersSingleOpen from "../components/freelancer/SuppliersSingleOpen";
// import FreelancerFavourites from "../components/freelancer/MyFavourites";
// import FreelancerSearch from "../components/snippet/SearchDashboardFree";

const DynamicFreelancerDashboard = lazy(() => import("../components/freelancer/dashboard"));
const DynamicFreelancerProfile = lazy(() => import("../components/freelancer/Profile"));
const DynamicFreelancerProfileChange = lazy(() => import("../components/freelancer/ProfileChange"));
const DynamicFreelancerProfileComplete = lazy(() => import("../components/freelancer/ProfileComplete"));
const DynamicFreelancerMembership = lazy(() => import("../components/freelancer/MembershipPlan"));
const DynamicFreelancerPayment = lazy(() => import("../components/freelancer/Payment"));
const DynamicFreelancerAccepted = lazy(() => import("../components/freelancer/AcceptedJobs"));
const DynamicFreelancerRequested = lazy(() => import("../components/freelancer/RequestJobs"));
const DynamicFreelancerCompleted = lazy(() => import("../components/freelancer/CompletedJobs"));
const DynamicFreelancerSingleJob = lazy(() => import("../components/freelancer/SingleJob"));
const DynamicFreelancerChat = lazy(() => import("../components/freelancer/Chat"));
const DynamicFreelancerChatInner = lazy(() => import("../components/freelancer/ChatInner"));
const DynamicFreelancerCalendar = lazy(() => import("../components/freelancer/Calendar"));
const DynamicFreelancerCalendarAvailability = lazy(() => import("../components/freelancer/CalendarAvailability"));
const DynamicFreelancerLeaveRating = lazy(() => import("../components/freelancer/LeaveRating"));
const DynamicFreelancerMyRating = lazy(() => import("../components/freelancer/MyRating"));
const DynamicFreelancerNotifications = lazy(() => import("../components/freelancer/Notification"));
const DynamicFreelancerSuppliers = lazy(() => import("../components/freelancer/Suppliers"));
const DynamicFreelancerSuppliersSingle = lazy(() => import("../components/freelancer/SuppliersSingle"));
const DynamicFreelancerSuppliersSingleOpen = lazy(() => import("../components/freelancer/SuppliersSingleOpen"));
const DynamicFreelancerFavourites = lazy(() => import("../components/freelancer/MyFavourites"));

// import NotFound from '../components/notFound'

// import NotFound from '../components/notFound';
const DynamicNotFound = lazy(() => import('../components/notFound'));
const PrimarylayoutFree = () => {
    // const [path, setPath] = useState(true);
    const {pathname} = useLocation();
    const [search, setSearch] = useState([]);
    const [show, setShow] = useState(false);
    // console.log(pathname)

    // useEffect(() => {
    //     if(pathname === "/"){
    //         setPath(true);
    //     }else{
    //         setPath(false);
    //     }
    // }, []);

    const sendSearch = (e) => {
        setSearch(e);
        // console.log(e)
        setShow(true);
    }

    let AuthFree = localStorage.getItem('auth_empplyFreeUID');
    // const AllJobs = search;

    if(!AuthFree){
        return <Redirect push to="/" />
    }

    // if(show){
    //     return <Redirect to="/f/search" />
    // }

    const handleClose = () => setShow(false);

    // console.log(AuthFree)
    return (
        <div id="wrapper">
            <Suspense fallback={<div>Loading...</div>}>
            {AuthFree && <DynamicDashboardFreeHeader getSearch={(e) => sendSearch(e)} />}
                            
            {/* {AllJobs && AllJobs[0] ? 
                <Search jobs={search} /> : 
                (show ? <Search jobs={search} /> : 
                    ( */}
                        <>
                            
                            {/* {pathname === "/f/search" && <Search jobs={search} />} */}
                            {pathname === "/f/profile" && <DynamicFreelancerProfile />}
                            {pathname === "/f/change-password" && <DynamicFreelancerProfileChange />}
                            {pathname === "/f/complete-profile" && <DynamicFreelancerProfileComplete />}
                            {pathname === "/f/membership" && <DynamicFreelancerMembership />}
                            {pathname === "/f/payment" && <DynamicFreelancerPayment />}
                            {pathname === "/f/accepted" && <DynamicFreelancerAccepted />}
                            {pathname === "/f/offers" && <DynamicFreelancerRequested />}
                            {pathname === "/f/completed" && <DynamicFreelancerCompleted />}
                            <Route path="/f/job/:id">
                                <DynamicFreelancerSingleJob />
                            </Route>
                            {pathname === "/f/chat" && <DynamicFreelancerChat />}
                            <Route path="/f/user/:id">
                                <DynamicFreelancerChatInner />
                            </Route>
                            {pathname === "/f/calendar" && <DynamicFreelancerCalendar />}
                            {pathname === "/f/availability" && <DynamicFreelancerCalendarAvailability />}
                            {pathname === "/f/leave-rating" && <DynamicFreelancerLeaveRating />}
                            {pathname === "/f/my-rating" && <DynamicFreelancerMyRating />}
                            {pathname === "/f/my-favourites" && <DynamicFreelancerFavourites />}
                            {pathname === "/f/notifications" && <DynamicFreelancerNotifications />}
                            {pathname === "/f/suppliers" && <DynamicFreelancerSuppliers />}
                            <Route path="/f/supplier/:id">
                                {AuthFree ? <DynamicFreelancerSuppliersSingle /> : <DynamicFreelancerSuppliersSingleOpen />}
                            </Route>
                            {pathname === "/" && <DynamicFreelancerDashboard />}
                            {/* {pathname === "/f/search" && <Search jobs={search} />} */}

                            {/* <Route path="*" component={NotFound} /> */}
                            <DynamicNotFound view="dashboard" />
                        </>
                    {/* )
                )
            } */}
            </Suspense>

            <Modal show={show} centered={true} size="lg" onHide={handleClose}>
                <Modal.Header className='border-0' closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0 px-0 px-lg-3'>
                    <Suspense fallback={<div>Loading...</div>}>
                        <DynamicSearch modal={handleClose} results={search} />
                    </Suspense>
                </Modal.Body>
            </Modal>
            
        </div>
    );
}

export default React.memo(PrimarylayoutFree);