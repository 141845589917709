import React, { lazy, useState, Suspense } from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

// snipet
// import DashboardOrgHeader from '../components/organizer/DashboardHeader';
// import Search from '../components/snippet/SearchDashboardOrg';

const DynamicDashboardOrgHeader = lazy(() => import('../components/organizer/DashboardHeader'));
const DynamicSearch = lazy(() => import('../components/snippet/SearchDashboardOrg'));

// import OrganizerDashboard from "../components/organizer/dashboard";
// import OrganizerProfile from "../components/organizer/Profile";
// import OrganizerProfileChange from "../components/organizer/ProfileChange";
// import OrganizerJobPost from "../components/organizer/PostAJob";
// import OrganizerJobPostUpdate from "../components/organizer/PostAJobUpdate";
// import OrganizerCurrentJobs from "../components/organizer/CurrentJobs";
// import OrganizerMemberShip from "../components/organizer/MembershipPlan";
// import OrganizerPayment from "../components/organizer/Payment";
// import OrganizerRating from "../components/organizer/MyRating";
// import OrganizerPreviousJobs from "../components/organizer/PreviousJobs";
// import OrganizerMessage from "../components/organizer/Chat";
// import OrganizerMessageInner from "../components/organizer/ChatInner";
// import OrganizerCalendar from "../components/organizer/Calendar";
// import OrganizerThankYou from "../components/organizer/ThankYou";
// import OrganizerSingleJob from "../components/organizer/SingleJob";
// import OrganizerLeaveRating from "../components/organizer/LeaveRating";
// import OrganizerFreelancerSingle from "../components/organizer/FreelancerSingle";
// import OrganizerNotifications from "../components/organizer/Notification";
// import OrganizerSuppliers from "../components/organizer/Suppliers";
// import OrganizerSuppliersSingle from "../components/organizer/SuppliersSingle";
// import OrganizerSuppliersSingleOpen from "../components/organizer/SuppliersSingleOpen";
// import OrganizerFavourites from "../components/organizer/MyFavourites";
// import ThankYouPayment from "../components/organizer/ThankYouPayment";

const DynamicOrganizerDashboard = lazy(() => import("../components/organizer/dashboard"));
const DynamicOrganizerProfile = lazy(() => import("../components/organizer/Profile"));
const DynamicOrganizerProfileChange = lazy(() => import("../components/organizer/ProfileChange"));
const DynamicOrganizerJobPost = lazy(() => import("../components/organizer/PostAJob"));
const DynamicOrganizerJobPostUpdate = lazy(() => import("../components/organizer/PostAJobUpdate"));
const DynamicOrganizerCurrentJobs = lazy(() => import("../components/organizer/CurrentJobs"));
const DynamicOrganizerMemberShip = lazy(() => import("../components/organizer/MembershipPlan"));
const DynamicOrganizerPayment = lazy(() => import("../components/organizer/Payment"));
const DynamicOrganizerRating = lazy(() => import("../components/organizer/MyRating"));
const DynamicOrganizerPreviousJobs = lazy(() => import("../components/organizer/PreviousJobs"));
const DynamicOrganizerMessage = lazy(() => import("../components/organizer/Chat"));
const DynamicOrganizerMessageInner = lazy(() => import("../components/organizer/ChatInner"));
const DynamicOrganizerCalendar = lazy(() => import("../components/organizer/Calendar"));
const DynamicOrganizerThankYou = lazy(() => import("../components/organizer/ThankYou"));
const DynamicOrganizerSingleJob = lazy(() => import("../components/organizer/SingleJob"));
const DynamicOrganizerLeaveRating = lazy(() => import("../components/organizer/LeaveRating"));
const DynamicOrganizerFreelancerSingle = lazy(() => import("../components/organizer/FreelancerSingle"));
const DynamicOrganizerNotifications = lazy(() => import("../components/organizer/Notification"));
const DynamicOrganizerSuppliers = lazy(() => import("../components/organizer/Suppliers"));
const DynamicOrganizerSuppliersSingle = lazy(() => import("../components/organizer/SuppliersSingle"));
const DynamicOrganizerSuppliersSingleOpen = lazy(() => import("../components/organizer/SuppliersSingleOpen"));
const DynamicOrganizerFavourites = lazy(() => import("../components/organizer/MyFavourites"));
const DynamicThankYouPayment = lazy(() => import("../components/organizer/ThankYouPayment"));
const DynamicNotFound = lazy(() => import('../components/notFound'));

// import NotFound from '../components/notFound'

// import NotFound from '../components/notFound';
// import NotFound from '../components/notFound';

const PrimarylayoutOrg = () => {
    const {pathname} = useLocation();
    const [search, setSearch] = useState([]);
    const [show, setShow] = useState(false);

    const sendSearch = (e) => {
        setSearch(e);
        // console.log(e)
        setShow(true);
    }

    let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
    // const AllJobs = search;

    if(!AuthOrg){
        return <Redirect push to="/" />
    }
    const handleClose = () => setShow(false);
    
    return (
        <div id="wrapper">
            <Suspense fallback={<div>Loading...</div>}>
            {AuthOrg && <DynamicDashboardOrgHeader getSearch={(e) => sendSearch(e)} />}
            
            {/* {AllJobs && AllJobs[0] ? 
                <Search jobs={search} /> : 
                (show ? <Search jobs={search} /> : 
                    ( */}
                    {/* <Router>
                        <Switch> */}
                        <>
                            {/* {pathname === "/e/search" && <Search jobs={search} />} */}
                            <Route path="/e/profile" component={DynamicOrganizerProfile} />
                            <Route path="/e/change-password" component={DynamicOrganizerProfileChange} />
                            <Route path="/e/job-post" component={DynamicOrganizerJobPost} />
                            {/* {pathname === "/e/job-post-update/:id" && <OrganizerJobPostUpdate />} */}
                            <Route path="/e/job-post-update/:id">
                                <DynamicOrganizerJobPostUpdate />
                            </Route>
                            <Route path="/e/current-jobs" component={DynamicOrganizerCurrentJobs} />
                            <Route path="/e/membership" component={DynamicOrganizerMemberShip} />
                            <Route path="/e/payment" component={DynamicOrganizerPayment} />
                            <Route path="/e/my-rating" component={DynamicOrganizerRating} />
                            <Route path="/e/previous-jobs" component={DynamicOrganizerPreviousJobs} />
                            <Route path="/e/chat" component={DynamicOrganizerMessage} />
                            <Route path="/e/user/:id">
                                <DynamicOrganizerMessageInner />
                            </Route>
                            <Route path="/e/calendar" component={DynamicOrganizerCalendar} />
                            <Route path="/e/thank-you" component={DynamicOrganizerThankYou} />
                            <Route path="/e/thank-you-payment" component={DynamicThankYouPayment} />
                            <Route path="/e/job/:id">
                                <DynamicOrganizerSingleJob />
                            </Route>
                            <Route path="/e/leave-rating" component={DynamicOrganizerLeaveRating} />
                            {/* {pathname === "/e/freelancer/:id" && <OrganizerFreelancerSingle />} */}
                            <Route path="/e/freelancer/:id">
                                <DynamicOrganizerFreelancerSingle />
                            </Route>
                            <Route path="/e/notifications" component={DynamicOrganizerNotifications} />
                            <Route path="/e/suppliers" component={DynamicOrganizerSuppliers} />
                            <Route path="/e/my-favourites" component={DynamicOrganizerFavourites} />
                            <Route path="/e/supplier/:id">
                                {AuthOrg ? <DynamicOrganizerSuppliersSingle /> : <DynamicOrganizerSuppliersSingleOpen />}
                            </Route>
                            {/* <Route path="/" component={} /> */}
                            {pathname === "/" && <DynamicOrganizerDashboard />}
                            <DynamicNotFound view="dashboard" />
                        </>
                        {/* </Switch>
                    </Router> */}
                    {/* )
                )} */}
                </Suspense>
                {/* <Route path="*" component={NotFound} /> */}

                <Modal show={show} centered={true} size="lg" onHide={handleClose}>
                    <Modal.Header className='border-0' closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-0 px-0 px-lg-3'>
                        <Suspense fallback={<div>Loading...</div>}>
                            <DynamicSearch modal={handleClose} results={search} />
                        </Suspense>
                    </Modal.Body>
                </Modal>
        </div>
    );
}

export default React.memo(PrimarylayoutOrg);