import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-phone-input-2/lib/style.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/style-upgrade.css';
import './assets/css/responsive-upgrade.css';
import './assets/fonts.css';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

  
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
